import * as React from 'react';
import { chakra } from '@chakra-ui/react';

const ChakraDt = chakra('dt');

const Dt = ({ children }: { children: React.ReactNode }) => {
  return (
    <ChakraDt
      fontWeight="bold"
      fontSize={{ base: 'xl', md: '2xl', xl: '4xl' }}
      mb={4}
    >
      {children}
    </ChakraDt>
  );
};

export default Dt;
