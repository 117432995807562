import * as React from 'react';
import { Text, Box } from '@chakra-ui/react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Dl from '../components/Dl';
import Dt from '../components/Dt';
import Dd from '../components/Dd';
import { maxW } from '../assets/config';
import JsonLd from '../components/JsonLd';

const PrivacyPage = () => {
  return (
    <Layout>
      <Seo
        title="プライバシーポリシー"
        description="ChokerJokerのプライバシーポリシーについて説明します。"
      />
      <JsonLd
        title="プライバシーポリシー"
        description="ChokerJokerのプライバシーポリシーについて説明します。"
      />
      <Box maxW={maxW} mx="auto">
        <Text
          as="h2"
          py={4}
          my={8}
          fontSize={{ base: '2xl', md: '4xl', xl: '6xl' }}
          fontWeight="bold"
          borderBottom="2px solid black"
        >
          プライバシーポリシー
        </Text>
        <Dl>
          <Dd>
            ジョンワークス合同会社（以下、「当社」という。）は，ユーザーの個人情報について以下のとおりプライバシーポリシー（以下、「本ポリシー」という。）を定めます。本ポリシーは、当社がどのような個人情報を取得し、どのように利用・共有するか、ユーザーがどのようにご自身の個人情報を管理できるかをご説明するものです。
          </Dd>
          <Dt>1. 個人情報の取得方法</Dt>
          <Dd>
            本ポリシーは、当社が取得した個人情報（個人情報保護法第2条第1項により定義された個人情報）の取り扱いに適用されます。
          </Dd>
          <Dt>2.個人情報の利用目的 個人情報の利用目的は、以下の通りです。</Dt>
          <Dd>
            （1） ご本人確認
            <br /> （2） 当社サービスの提供
            <br /> （3） 当社商品に関するご案内、お問合せ等への対応
            <br /> （4）
            当社の利用規約、ポリシー等の制定、変更、改廃に関するご案内
            <br /> （5） 当社の掲げる規約に違反する行為への対応
            <br /> （6） 当社サービスの品質向上、改善及び開発
            <br /> （7） 上記買う号の利用目的に付随する目的のため
            <br />
          </Dd>
          <Dt>3.個人情報の利用目的の変更</Dt>
          <Dd>
            個人情報の利用目的は、変更前後の関連性について合理性が認められる場合に限って変更するものとします。
            個人情報の利用目的について変更を行った際は、変更後の目的について当社所定の方法によってユーザーに通知し、Webサイト上にも公表します。
          </Dd>
          <Dt>4.個人データの第三者提供について</Dt>
          <Dd>
            当社は以下の場合を除き、同意を得ないで第三者に個人情報を提供することは致しません。
            <br />
            <br />
            • 法令に基づく場合 <br />
            •
            人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
            <br />
            •
            公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
            <br />
            •
            国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
            <br />
            •
            当社が利用目的達成に必要な範囲内において、個人情報の取り扱いの全部又は一部を委託する事に伴い当該個人情報が提供される場合。
            <br />•
            当社が合併その他事由による事業承継に伴って個人情報を提供する場合。{' '}
            <br />
            • 個人情報保護法及び付随するその他の法令が規定する場合
            <br />
          </Dd>
          <Dt>5.安全管理体制</Dt>
          <Dd>
            当社は、取り扱う個人情報の安全管理のために必要且つ適切な措置を講じます。
          </Dd>
          <Dt>6.保有個人データの開示、訂正</Dt>
          <Dd>
            当社は、ご本人から個人情報保護法の定めに基づき個人情報の開示を求められたときには、それがお客様ご本人からのご請求である事を確認の上、遅滞なく本人に対しこれを開示します。また、ご本人から個人情報が真実ではないという理由により個人情報保護法の定めに基づき内容の訂正、追加又は削除を求められた際は、お客様ご本人からのご請求である事を確認の上、個人情報の内容の訂正、追加又は削除を行い、その旨をお客様に通知します。但し、個人情報保護法その他法令により、当社がその義務を負わない場合はこの限りではありません。
          </Dd>
          <Dt>7.技術利用</Dt>
          <Dd>
            （1）当社はCookieを使用しています。Cookieとは、WebサーバーからWebブラウザに送信されるデータの事です。WebサーバーがCookieを参照する事でユーザーのパソコンを識別でき、効率的に当社Webサイトを利用する事ができます。当社WebサイトがCookieとして送るファイルは、個人を特定するような情報を含んでおりません。お使いのWebブラウザの設定により、Cookieを無効にする事も可能です。
            <br />
            （2）当社が提供するサービスの利用状況等の調査・分析などのため、GoogleLCCが提供するGoogleアナリティクスを利用しています。尚、お客様ご自身のデータがＧｏｏｇｌｅアナリティクスで使用される事を望まない場合は、Google社提供のGoogleアナリティクス
            オプトアウト アドオンをご利用ください。
            <br />
          </Dd>
          <Dt>8.免責事項 </Dt>
          <Dd>
            •
            当社Webサイトに掲載されている情報の正確さには万全を期していますが、利用者が当社Webサイトの情報を用いて行う一切の行為に関して、当社は一切の責任を負わないものとします。
            <br />•
            当社は、利用者が当社Webサイトを利用したことにより生じた利用者の損害及び利用者が第三者に与えた損害に関して、一切の責任を負わないものとします。
          </Dd>
          <Dt>9.著作権・肖像権</Dt>
          <Dd>
            当社Webサイト内の文章や画像、すべてのコンテンツは著作権・肖像権等により保護されており、無断での使用や転用は禁止されています。
          </Dd>
          <Dt>10.ポリシーの変更、改善</Dt>
          <Dd>
            当社は、個人情報の取り扱いの適宜改善に努め、その必要に応じて本ポリシーを変更する事があります。
          </Dd>
          <Dt>11.個人情報取扱いに関するお問合せ</Dt>
          <Dd>
            個人情報開示等のお申し出、ご意見、ご質問、苦情のお申し出及びその他個人情報の取り扱いに関するお問合せは下記よりご連絡ください。
            <br />
            <br />
            電子メール
            <a href="mailto:info@chokerjoker.com">
              <u>info@chokerjoker.com</u>
            </a>
            <br />
            郵送　〒350-0822　埼玉県川越市山田1600-4　
            <br />
            ジョンワークス合同会社　個人情報担当者宛
            <br />
          </Dd>
          <Dt>１２．プライバシーポリシーの制定日及び改定日</Dt>
          <Dd> 2022年5月1日　制定</Dd>
        </Dl>
      </Box>
    </Layout>
  );
};

export default PrivacyPage;
